
import * as ipxRuntime$mbsYiuxaCHaO7tg3ASzLraOat5XjXoz_45xgeCSqnMIJ8 from '/app/apps/nuxt-front/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "weengrave.com",
    "apkhomedecor.com",
    "we-engrave-pictures.s3.us-west-1.amazonaws.com",
    "we-engrave-pictures.s3.amazonaws.com",
    "we-engrave-stage.s3.us-west-1.amazonaws.com",
    "we-engrave-stage.s3.amazonaws.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$mbsYiuxaCHaO7tg3ASzLraOat5XjXoz_45xgeCSqnMIJ8, defaults: {} }
}
        